<template>
  <div>
    <vs-popup class="holamundo " title="" :active.sync="isActive" @close="closePopup">
        <div v-if="currentBooking && currentBooking.receipt.booking_option">
            <div class="vx-col p-0 w-full flex flex-wrap justify-between mb-5" v-if="bookingConfirmationType == 'bookingConfirmation'">
                <vs-button icon="check" color="success" class="w-2/3 text-sm" @click="updateBookingState('accept')">{{ $t("acceptBooking")}}</vs-button>
                <vs-button icon="block" type="border" color="danger" class="w-1/4" @click="rejectBookingConfirmation">{{ $t("rejectBooking")}}</vs-button>
            </div>
            <div class="vx-col p-0 w-full flex flex-wrap justify-between mb-5" v-else-if="bookingConfirmationType == 'bookingVisit'">
                <vs-button icon="check" color="success" class="w-2/3 text-sm" @click="updateBookingState('booking_check_accept')">{{ $t("acceptBookingVisit")}}</vs-button>
                <vs-button icon="block" type="border" color="danger" class="w-1/4" @click="updateBookingState('booking_check_reject')">{{ $t("rejectBookingVisit")}}</vs-button>
            </div>
            <div class="vx-col p-0 w-full flex flex-wrap justify-between mb-5" v-else>
                <vs-button icon="check" color="success" class="w-2/3 text-sm" @click="updateBookingState('booking_check_accept')">{{ $t("bookingCheckAccept")}}</vs-button>
                <vs-button icon="block" type="border" color="danger" class="w-1/4" @click="updateBookingState('booking_check_reject')">{{ $t("bookingCheckReject")}}</vs-button>
            </div>
            <div class="vx-col p-0 w-full mb-8">
                <vs-alert v-if="bookingConfirmationType == 'bookingConfirmation'" color="warning" icon="note" class="h-full text-center lg:text-left">
                    {{$t('addBookingSidebar.bookingConfirmationAlert')}}
                </vs-alert>
                <vs-alert v-else-if="bookingConfirmationType == 'bookingVisit'" color="warning" icon="note" class="h-full text-center lg:text-left">
                    {{$t('addBookingSidebar.bookingVisitAlert')}}
                </vs-alert>
                <vs-alert v-else color="warning" icon="note" class="h-full text-center lg:text-left">
                    {{$t('bookingCheckConfirmationAlert')}}
                </vs-alert>

            </div>
            <div class="vx-col p-0 w-full flex flex-wrap lg:justify-between justify-center">
                <h3 class="opacity-75 my-2 text-center lg:my-0 lg:text-right">{{currentBooking.receipt.booking_option != null ? currentBooking.receipt.booking_option.name : ''}}</h3>
            </div>
            <div class="vx-col p-0 w-full flex flex-wrap justify-center">
                <hr class="row w-11/12 mt-3" style="opacity: 0.5">
            </div>
            <div class="vx-col p-0 w-full flex flex-wrap justify-between mt-5">
                <h3 class="opacity-75 text-lg lg:text-2xl"><i class="far fa-solid fa-gifts"></i> {{$t('addBookingSidebar.eventType')}}</h3>
                <h3 class="text-lg lg:text-2xl">{{currentBooking.event_type ? currentBooking.event_type.arabic_name : ''}}</h3>
            </div>
            <div class="vx-col p-0 w-full flex flex-wrap justify-between mt-5" v-for="guestInput in currentBooking.event_type.guests_inputs" :key="guestInput.key">
                <h3 class="opacity-75 text-lg lg:text-2xl"><i class="far fa-solid fa-ballot-check"></i> {{guestInput.name}}</h3>
                <h3 class="text-lg lg:text-2xl">{{currentBooking[guestInput.key] > 0 ? currentBooking[guestInput.key] : ''}}</h3>
            </div>
            <div class="vx-col p-0 w-full flex flex-wrap justify-between mt-5">
                <h3 class="opacity-75 text-lg lg:text-2xl"><i class="far fa-solid fa-calendar-check"></i> {{$t('addBookingSidebar.bookingDate')}}</h3>
                <h3 class="text-lg lg:text-2xl">{{currentBooking.start_date ? currentBooking.start_date : ''}}</h3>
            </div>
            <div class="vx-col p-0 w-full flex flex-wrap justify-between mt-5" v-if="currentBooking.start_time && currentBooking.end_time">
                <h3 class="opacity-75 text-lg lg:text-2xl"><i class="far fa-solid fa-clock"></i> {{$t('booking.bookingTime')}}</h3>
                <div>
                  <h3 class="text-lg lg:text-2xl">{{currentBooking.start_time ? convertToAmPm(currentBooking.start_time) : ''}}</h3>
                  <h3 class="text-lg lg:text-2xl">{{currentBooking.end_time ? convertToAmPm(currentBooking.end_time) : ''}}</h3>
                </div>
            </div>
            <div class="vx-col p-0 w-full flex flex-wrap justify-between mt-5" v-if="bookingConfirmationType == 'bookingVisit'">
                <vs-textarea :label="$t('addBookingSidebar.visitDetails')" rows="5" :value="currentBooking.customer_notes" class="mt-5 w-full" readonly/>
            </div>

            <div class="vx-col p-0 w-full flex flex-wrap md:justify-between justify-center mt-5" v-if="bookingConfirmationType == 'bookingConfirmation'">
                <h3 class="opacity-75 mx-2"><i class="far fa-solid fa-dollar-sign ml-1 mr-1"></i> {{$t('addBookingSidebar.bookingFinalValue')}}</h3>
                <div>
                    <h3 class="text-danger mx-2">
                        {{currentBooking.receipt.final_price_for_agent ? currentBooking.receipt.final_price_for_agent.toLocaleString() : 0 + ' '}} {{$t("SAR")}}
                    </h3>
                    <div class="vx-col p-0 w-full flex flex-wrap justify-center">
                        <small class="very-small-note">{{$t('vatIncluded')}}</small>
                    </div>
                </div>
            </div>
            <div class="vx-col p-0 w-full flex flex-wrap justify-between mt-0" v-if="bookingConfirmationType == 'bookingConfirmation' && currentBooking.receipt && currentBooking.receipt.booking_copy && currentBooking.receipt.booking_copy.internal_services.length > 0">
                <h3 class="opacity-75 text-lg lg:text-2xl"><i class="far fa-solid fa-layer-plus"></i> {{$t('addBookingSidebar.servicesNumber', { servicesCount: currentBooking.receipt.booking_copy.internal_services.length })}}</h3>
            </div>
            <div  v-if="bookingConfirmationType == 'bookingConfirmation'">
                <div class="vx-col p-0 w-full flex flex-wrap md:justify-between justify-center" v-if="currentBooking.state_id == 3">
                    <h3 class="opacity-75 text-success md:mt-2 mt-5" v-if="currentBooking.state_id == 3"><i class="far fa-solid fa-check"></i> {{$t('depositPaid')}}</h3>
                    <h3 class="md:mt-2 mt-5" v-if="currentBooking.receipt.qosoor_discount > 0">
                        <vs-chip color="#6a0dad">
                            <vs-avatar icon="celebration" color="#6a0dad" />
                        {{$t('eventfulDiscount')}}
                        </vs-chip>
                    </h3>
                    <h3 class="md:mt-2 mt-5" v-if="currentBooking.receipt.agent_discount > 0">
                        <vs-chip color="#6a0dad">
                            <vs-avatar icon="celebration" color="#6a0dad" />
                        {{$t('eventfulDiscount')}}
                        </vs-chip>
                    </h3>
                </div>
                <div class="vx-col p-0 w-full flex flex-wrap md:justify-end justify-center" v-else>
                    <h3 class="md:mt-2 mt-5" v-if="currentBooking.receipt.qosoor_discount > 0">
                        <vs-chip color="#6a0dad">
                            <vs-avatar icon="celebration" color="#6a0dad" />
                        {{$t('eventfulDiscount')}}
                        </vs-chip>
                    </h3>
                    <h3 class="md:mt-2 mt-5" v-if="currentBooking.receipt.agent_discount > 0">
                        <vs-chip color="#10163A">
                            <vs-avatar icon="celebration" color="#10163A" />
                        {{$t('agentDiscount', {venueName: currentVenue.arabic_name})}}
                        </vs-chip>
                    </h3>
                </div>

            </div>
            <vs-textarea v-if="bookingConfirmationType == 'bookingConfirmation' && currentBooking.customer_notes" :label="$t('addBookingSidebar.customerNotes')" :value="currentBooking.customer_notes" class="mt-5 w-full" readonly/>
        </div>
    </vs-popup>
        <vs-prompt v-if="currentBooking" :title="$t('home.editBookingOptionForm.rejectConfirmPrompt.title')" color="danger" :buttons-hidden="true" :active.sync="rejectConfirmationPrompt">
        <div v-if="currentBooking">
            <div class="vx-col w-full" v-if="cancelationReasonRadio == null">
                <div class="container mt-2">
                    <div class="vx-row required">
                        <label class="v-select-label">{{$t('bookingConfirmation.rejection.why')}}</label>
                    </div>
                    <div class="vx-row">
                        <vs-radio class="mr-6 mt-2" v-model="cancelationReasonRadio" vs-name="unavailable_venue_in_date" vs-value="unavailable_venue_in_date">{{$t('bookingConfirmation.rejection.unavailable_venue_in_date', {date: currentBooking.start_date})}}</vs-radio>
                        <vs-radio class="mr-2 mt-2" v-model="cancelationReasonRadio" vs-name="unavailable_booking_option_in_date" vs-value="unavailable_booking_option_in_date">{{$t('bookingConfirmation.rejection.unavailable_booking_option_in_date', {option_name: currentBooking.receipt.booking_option.name, date: currentBooking.start_date})}}</vs-radio>
                        <vs-radio class="mr-6 mt-2" v-model="cancelationReasonRadio" vs-name="other" vs-value="other">{{$t('bookingConfirmation.rejection.other')}}</vs-radio>
                    </div>
                </div>
            </div>
            <div v-else>
                <div class="vx-col w-full md:mb-2 pl-2" v-if="cancelationReasonRadio == 'unavailable_venue_in_date'">
                    <div class="flex w-full mb-3">
                        <vs-button size="small" type="border" @click="cancelationReasonRadio = null; suggestedDates=[]">{{ $t('back') }}</vs-button>
                    </div>
                    <div class="container mt-2">
                        <div class="vx-row required">
                            <label class="v-select-label">{{$t('bookingConfirmation.rejection.chooseAvailableDate')}}</label>
                        </div>
                        <div class="flex w-full">
                            <ul class="flex flex-wrap w-full">
                                <li v-for="suggestedDate in suggestedDatesArr" :key="suggestedDate" class="mt-2">
                                    <vs-checkbox v-model="suggestedDates" :vs-value="suggestedDate">{{suggestedDate}}</vs-checkbox>
                                </li>
                            </ul>
                        </div>

                        <div class="flex flex-col w-full space-y-3 mt-5" v-if="suggestedDates.length > 0">
                            <div>
                                {{ $t("bookingConfirmation.rejection.option_and_date_will_be_blocked", {option_name: currentBooking.receipt.booking_option.name, date: currentBooking.start_date})}}
                            </div>
                            <vs-button icon="block" color="danger" class="w-full" @click="blockDateAgentSelection = 'option_only'; updateBookingState('reject')">{{ $t("bookingConfirmation.rejection.yes_block_it")}}</vs-button>
                            <vs-button icon="check" color="success" type="border" class="w-full" @click="blockDateAgentSelection = null; updateBookingState('reject')">{{ $t("bookingConfirmation.rejection.do_not_block_it")}}</vs-button>
                            <div>
                                {{ $t("bookingConfirmation.rejection.do_not_do_it_again")}}
                            </div>
                        </div>
                    </div>
                </div>
                <div class="vx-col w-full md:mb-2 pl-2" v-if="cancelationReasonRadio == 'unavailable_booking_option_in_date'">
                    <div class="flex w-full mb-3">
                        <vs-button size="small" type="border" @click="cancelationReasonRadio = null; suggestedDates=[]; alternativeBookingOptions=[]">{{ $t('back') }}</vs-button>
                    </div>

                    <div class="container mt-2">
                        <div class="vx-row required">
                            <label class="v-select-label">{{$t('bookingConfirmation.rejection.chooseAvailableDate')}}</label>
                        </div>
                        <div class="flex w-full">
                            <ul class="flex flex-wrap w-full">
                                <li v-for="suggestedDate in suggestedDatesArr" :key="suggestedDate" class="mt-2">
                                    <vs-checkbox v-model="suggestedDates" :vs-value="suggestedDate">{{suggestedDate}}</vs-checkbox>
                                </li>
                            </ul>
                        </div>

                        <div class="my-5"></div>

                        <div class="vx-row required">
                            <label class="v-select-label">{{$t('bookingConfirmation.rejection.chooseAlternativeBookingOptions')}}</label>
                        </div>
                        <div class="flex w-full">
                            <ul class="centerx w-full">
                                <li v-for="bookingOption in bookingOptionsList" :key="bookingOption.id" class="mt-2">
                                    <vs-checkbox v-model="alternativeBookingOptions" :vs-value="bookingOption">{{bookingOption.name}}</vs-checkbox>
                                </li>
                            </ul>
                        </div>
                    </div>

                    <div class="flex flex-col w-full space-y-3 mt-5" v-if="suggestedDates.length > 0 && alternativeBookingOptions.length > 0">
                        <div>
                            {{ $t("bookingConfirmation.rejection.date_will_be_blocked", {date: currentBooking.start_date})}}
                        </div>
                        <vs-button icon="block" color="danger" class="w-full" @click="blockDateAgentSelection = 'whole_venue'; updateBookingState('reject')">{{ $t("bookingConfirmation.rejection.yes_block_it")}}</vs-button>
                        <vs-button icon="check" color="success" type="border" class="w-full" @click="blockDateAgentSelection = null; updateBookingState('reject')">{{ $t("bookingConfirmation.rejection.do_not_block_it")}}</vs-button>
                        <div>
                            {{ $t("bookingConfirmation.rejection.do_not_do_it_again")}}
                        </div>
                    </div>
                </div>
                <div class="vx-col w-full md:mb-2 mt-5 pl-2" v-if="cancelationReasonRadio == 'other'">
                    <h5>{{$t('bookingConfirmation.rejection.writeRejectionReason')}}</h5>
                    <div class="flex w-full">
                        <vs-input v-model="otherCancelationReason" :placeholder="$t('bookingConfirmation.rejection.rejection_reason_placeholder')" class="w-full"/>
                    </div>

                    <div class="flex flex-col w-full space-y-3 mt-5" v-if="otherCancelationReason.length > 5">
                        <div>
                            {{ $t("bookingConfirmation.rejection.rejection_reason_will_be_sent_to_customer")}}
                        </div>
                        <vs-button icon="block" color="danger" type="border" class="w-full" @click="blockDateAgentSelection = null; updateBookingState('reject')">{{ $t("bookingConfirmation.rejection.send_rejection_reason")}}</vs-button>
                    </div>
                </div>
            </div>
        </div>
    </vs-prompt>
  </div>
</template>

<script>
import moment from 'moment'
export default {

  props: {
    currentBooking: {
        type: Object,
        defult: null
    },
    bookingConfirmationPopUp: {
      type: Boolean,
      default: false
    },
    bookingConfirmationType: {
      type: String,
      default: 'bookingConfirmation'
    },

  },
  data () {
    return {
        rejectConfirmationPrompt: false,
        cancelationReasonRadio: null,
        cancelationReason: 'unavailable_date',
        otherCancelationReason: '',
        suggestedDatesArr: null,
        suggestedDates: [],
        alternativeBookingOptions: [],
        blockDateAgentSelection: null
    }
  },
  computed: {
    isActive: {
        get() {
            return this.bookingConfirmationPopUp
        },
        set(newState) {
            return newState
        }
    },
    currentVenueId () {
        return this.$store.getters.currentVenueId
    },
    allBookingStates () {
        return this.$store.state.bookingStates.allBookingStates
    },
    currentVenue () {
        return this.$store.getters.currentVenue? JSON.parse(this.$store.getters.currentVenue): {};
    },
    bookingOptionsList () {
        return this.$store.state.dataList.bookingOptionsList
    },
  },
  created () {
    this.$store.dispatch('bookingStates/getAllBookingStates')
  },
  methods: {
    convertToAmPm(time) {
          // Split the time into hours, minutes, and optionally seconds
          const [hours, minutes] = time.split(":").map(Number);

          // Determine AM or PM
          const period = hours >= 12 ? "PM" : "AM";

          // Convert hours to 12-hour format and ensure two digits
          const formattedHours = String(hours % 12 || 12).padStart(2, '0');

          // Ensure minutes are two digits
          const formattedMinutes = String(minutes).padStart(2, '0');

          // Return the formatted time
          return `${period} ${formattedHours}:${formattedMinutes}`;
      },
    closePopup() {
        this.removeBookingIdQueryParameter();
        this.$emit('update:bookingConfirmationPopUp', false);
    },
    updateBookingState(buttonClicked) {
        let statesInUse = {
            depositPaidId: this.allBookingStates.find(state => state.name == 'deposit_paid').id, // 3
            confirmedId: this.allBookingStates.find(state => state.name == 'confirmed').id, // 4
            bookingIssueId : this.allBookingStates.find(state => state.name == 'booking_issue').id,
            waitingReservationConfirmationId: this.allBookingStates.find(state => state.name == 'waiting_reservation_confirmation').id, // 16
            confirmedWithDepositPaidId: this.allBookingStates.find(state => state.name == 'confirmed_with_deposit_paid').id, // 17
            bookingCheckAcceptId: this.allBookingStates.find(state => state.name == 'booking_check_accepted').id, // 24
            bookingCheckRejectId: this.allBookingStates.find(state => state.name == 'booking_check_rejected').id, // 25
            bookingRejectedId: this.allBookingStates.find(state => state.name == 'rejected').id, // 28
        };

        let state = statesInUse.bookingIssueId; // set default, it will definitely change in the following logic
        let smsMessage = null;
        let what_to_block = null;

        if(buttonClicked == 'accept') {
            if(this.currentBooking.state_id == statesInUse.depositPaidId) state = statesInUse.confirmedWithDepositPaidId
            else if(this.currentBooking.state_id == statesInUse.waitingReservationConfirmationId) state = statesInUse.confirmedId
            else state = this.currentBooking.state_id;
        } else if(buttonClicked == 'reject') {

            if(['waiting_reservation_confirmation', 'deposit_paid'].includes(this.currentBooking.state.name)) {
                state = statesInUse.bookingRejectedId;
            } else {
                state = statesInUse.bookingCheckRejectId;
            }

            let invalid = false;
            if(this.cancelationReasonRadio == null) {
                this.$vs.notify({
                    color:'danger',
                    title: this.$t('error'),
                    text: this.$t('bookingConfirmation.rejection.pleaseFillFormWithInformation'),
                });
                this.rejectConfirmationPrompt = true;
                return;
            }
            if(this.cancelationReasonRadio == 'unavailable_venue_in_date') {
                if(this.suggestedDates.length == 0) {
                    this.$vs.notify({
                        color:'danger',
                        title: this.$t('error'),
                        text: this.$t('bookingConfirmation.rejection.pleaseFillFormWithInformation'),
                    });
                    this.rejectConfirmationPrompt = true;
                    return;
                }
                smsMessage = `نعتذر منك، المسؤول في ${this.currentVenue.arabic_name} يقول التاريخ غير متاح ❌`;
                smsMessage += "\n";

                smsMessage += "بس تقدر تحجز أوقات ثانية عن طريق التطبيق";
                smsMessage += "\n";

                smsMessage += `- خيار الحجز: \n ${this.currentBooking.receipt.booking_option.name} \n`;

                smsMessage += "- تواريخ بديلة:";
                smsMessage += "\n";

                this.suggestedDates.forEach(date => {
                    smsMessage += `(${date}), `;
                });

                smsMessage += "\n";
                smsMessage += "\n";
                smsMessage += "شكرًا لاتختياركم تطبيق ايفينتفل 👑";

                this.otherCancelationReason = smsMessage;

                what_to_block = this.blockDateAgentSelection;
                // state = statesInUse.bookingCheckRejectId;
            } else if(this.cancelationReasonRadio == 'unavailable_booking_option_in_date') {
                if(this.suggestedDates.length == 0) {
                    this.$vs.notify({
                        color:'danger',
                        title: this.$t('error'),
                        text: this.$t('home.editBookingOptionForm.rejectConfirmPrompt.pleaseSelectSuggestedDates'),
                    });
                    this.rejectConfirmationPrompt = true;
                    return;
                }
                smsMessage = `نعتذر منك، المسؤول في ${this.currentVenue.arabic_name} يقول التاريخ غير متاح ❌`;
                smsMessage += "\n";

                smsMessage += "بس تقدر تحجز باقات أو أوقات ثانية عن طريق التطبيق";
                smsMessage += "\n";

                if(this.alternativeBookingOptions.length > 0) {
                    smsMessage += "- خيارات بديلة:";
                    smsMessage += "\n";
                    this.alternativeBookingOptions.forEach(option => {
                    smsMessage += `(${option.name})\n`;
                });
                }

                smsMessage += "- تواريخ بديلة:";
                smsMessage += "\n";

                this.suggestedDates.forEach(date => {
                    smsMessage += `(${date}), `;
                });

                smsMessage += "\n";
                smsMessage += "\n";
                smsMessage += "شكرًا لاتختياركم تطبيق ايفينتفل 👑";

                this.otherCancelationReason = smsMessage;

                what_to_block = this.blockDateAgentSelection;
                // state = statesInUse.bookingCheckRejectId;
            } else if(this.cancelationReasonRadio == 'other') {
                if(!this.otherCancelationReason) {
                    this.$vs.notify({
                        color:'danger',
                        title: this.$t('error'),
                        text: 'تأكد من إدخال جميع المدخلات'
                    })
                    return;
                }

                smsMessage = `نعتذر منك، المسؤول في ${this.currentVenue.arabic_name} يقول التاريخ غير متاح ❌`;
                smsMessage += "\n";
                smsMessage += this.otherCancelationReason;

                console.log(smsMessage);

                this.otherCancelationReason = smsMessage;
                // state = statesInUse.bookingIssueId;
            }
        } else if(buttonClicked == 'booking_check_accept') {
            state = statesInUse.bookingCheckAcceptId;
        } else if(buttonClicked == 'booking_check_reject') {
            state = statesInUse.bookingCheckRejectId;
        }
        this.$store.dispatch('loader/loaderOn')
        this.$store.dispatch('dataList/updateState', {venueId:this.currentVenueId, bookingId: this.currentBooking.id ,stateId: state, cancelationReason: this.currentBooking.cancelation_reason, suggestedDates: this.suggestedDates, otherCancelationReason: this.otherCancelationReason, confirmation: buttonClicked, smsMessage: smsMessage, what_to_block: what_to_block})
            .then(e=>{
                let response = Array.isArray(e)? e[0].data:e.data;
                this.closePopup();
                if(buttonClicked != 'booking_check_accept' ) this.$emit('updateCurrentBooking', response.data.booking); else this.$emit('updateCurrentBooking', null);
                if(buttonClicked == 'accept') this.$emit('openPreview');
                if(buttonClicked == 'reject') this.rejectConfirmationPrompt = false;
                this.$vs.notify({
                    color:'success',
                    title: this.$t('editsDone'),
                    text:response.success_message || 'تم تغيير حالة الحجز بنجاح'
                })
            })
            .catch(function(error) {
                this.$vs.notify({
                    color:'danger',
                    title: this.$t('error'),
                    text: error||'عذرًا لم نستطع تعديل حجز لوجود خطأ ما، اذا تكرر الخطأ يُرجى التواصل مع الادارة، وشكرًا'
                })
            }.bind(this))
            .finally(()=>{this.$store.dispatch('loader/loaderOff')},this);
    },
    rejectBookingConfirmation(){
        this.closePopup();
        this.rejectConfirmationPrompt = true;
    },

  },
  watch: {
        currentBooking(newVal) {
            // if(newVal.cancelation_reason == null)
            //     this.currentBooking.cancelation_reason = 'unavailable_date';

            // to make a list of suggested dates:
            let dates = [];
            let startInterval = moment(newVal.start_date).subtract(4 ,'day');
            let endInterval = moment(newVal.start_date).add(4, 'day');
            let today = moment();
            while(startInterval.add(1, 'days').diff(endInterval) < 0) {
                if(startInterval.diff(today) > 1)
                    dates.push(startInterval.clone().format('DD-MM-YYYY'));
            }
            this.suggestedDatesArr = dates
        },
        isActive(newVal) {
            if(newVal) {
                this.alternativeBookingOptions = [];
                this.suggestedDates = [];
                this.cancelationReasonRadio = null;
            }
        }
    }

}
</script>

<style>

</style>
